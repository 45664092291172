import { sendGAEvent } from '@next/third-parties/google';

const convertToSnakeCase = (str: string) => str.toLowerCase().replace(/\s+/g, '_');
export const sendGaEvent = (params: Record<string, any>) => {
	// TODO: uncomment before testing locally
	// process.env.NEXT_PUBLIC_SITE_URL?.startsWith('https://')
	// if (window && window.gtag)
	// 	window.gtag('event', convertToSnakeCase(category), {
	// 		event_category: category,
	// 		event_label: label,
	// 		event_name: convertToSnakeCase(category),
	// 		value: value,
	// 		page_path: path,
	// 	});
	sendGAEvent('event', convertToSnakeCase(params?.category || 'custom_event'), {
		event_category: params?.category,
		event_label: params?.label,
		event_name: convertToSnakeCase(params?.category),
		value: params?.value,
		page_path: params?.path,
		...params,
	});
};
